import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { LangContext } from "../../store/lang-context";
import classes from "./NavBar.module.css";

const NavBar = () => {
  const isEng = useContext(LangContext).lang;
  const [position, setPosition] = useState("position0");
  const posArr = useMemo(() => {
    return ["position0", "position1", "position2", "position3"];
  }, []);
  const menuArr = useMemo(() => {
    return [
      { label: isEng ? "About" : "درباره من", link: "/" },
      { label: isEng ? "Projects" : "پروژه‌ها", link: "/projects" },
      { label: isEng ? "Skills" : "مهارت‌ها", link: "/skills" },
      { label: isEng ? "Contact" : "راه‌های تماس", link: "/contacts" },
    ];
  }, [isEng]);
  const changePosition = useCallback(
    (index) => {
      const formattedIndex = isEng ? index : 3 - index;
      setPosition(posArr[formattedIndex]);
    },
    [posArr, isEng]
  );
  const location = useLocation();

  useEffect(() => {
    const itemIndex = menuArr.findIndex(
      (item) => item.link === location.pathname
    );
    changePosition(itemIndex);
  }, [location, changePosition, menuArr]);

  return (
    <nav>
      <ul className={`${classes["header__list"]} `}>
        <div className={`${classes["underline"]} ${classes[position]}`}></div>
        <div className={`${classes["underline"]} ${classes[position]}`}></div>
        <div className={`${classes["underline"]} ${classes[position]}`}></div>
        <div className={`${classes["underline"]} ${classes[position]}`}></div>

        {menuArr.map((item, index) => {
          return (
            <NavLink
              className={(navData) =>
                navData.isActive
                  ? `${classes["list__item"]} ${classes["list__item--active"]}`
                  : `${classes["list__item"]}`
              }
              onClick={changePosition.bind(this, index)}
              to={item.link}
              key={item.label}
            >
              {item.label}
            </NavLink>
          );
        })}
      </ul>
    </nav>
  );
};

export default NavBar;
