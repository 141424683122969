import classes from "./About.module.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { LangContext } from "../../store/lang-context";
const About = () => {
  const isEng = useContext(LangContext).lang;
  return (
    <div>
      <h2 className={classes.header}>{isEng ? "Hi," : "سلام؛"}</h2>

      <p>
        {isEng
          ? `I am Ramin Shakooei. I worked as a graphic designer for social media for
        two years, and that experience taught me a lot, including how to be a
        competent and valuable team member also how to manage projects using
        project management software.`
          : `
          رامین شکویی هستم. به مدت دو سال در یک آژانس تبلیغاتی، طراح محتوای گرافیکی برای شبکه‌های اجتماعی بودم و در این راه تجربه‌های زیادی مثل مسئولیت‌پذیری، روحیه تیمی، مدیریت زمانی پروژه و ... را کسب کردم.  
          `}
      </p>
      <p>
        {isEng
          ? `Programming has always been my favorite subject. Whether I was younger
        and attempting to alter my blog's theme or build compact programs
        for bachelor's courses. I therefore made the decision to switch to
        programming in order to pursue my previous interest. I am overcoming a lot of obstacles, but I really enjoy when my code works correctly.`
          : `
        برنامه‌نویسی همیشه جزو علایق من بوده، چه زمانی که نوجوان بودم و تلاش می‌کردم قالب وبلاگم را تغییر دهم؛ چه زمان دانشجویی که برای پروژه‌های درسی برنامه‌نویسی می‌کردم. در نتیجه تصمیم گرفتم به سمت علاقه قدیمی خودم یعنی برنامه‌نویسی تغییر مسیر بدهم. در مسیر یادگیری با موانع مختلفی روبرو می‌شوم اما درست کار کردن برنامه برایم شیرین‌تر است.
        `}
      </p>

      {isEng ? (
        <p>
          You can visit some of my work
          <Link className={classes.link} to="/projects">
            {" "}
            here
          </Link>
          . As you can see, I enjoy simple layouts.
        </p>
      ) : (
        <p>
          نمونه کارهای من در
          <Link className={classes.link} to="/projects">
            {" "}
            اینجا{" "}
          </Link>
          قابل مشاهده است. همانطور که پیداست، من عاشق طراحی مینیمال هستم.
        </p>
      )}
    </div>
  );
};

export default About;
