import { useContext } from "react";
import { LangContext } from "../../store/lang-context";
import classes from "./LanguageBtn.module.css";

const LanguageBtn = () => {
  const langCtx = useContext(LangContext);

  const changeLangHandler = () => {
    langCtx.changeLang();
  };
  return (
    <div className={classes["circle"]} onClick={changeLangHandler}>
      {langCtx.lang ? "فا" : "En"}
    </div>
  );
};

export default LanguageBtn;
