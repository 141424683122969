import styles from "./Header.module.css";

import profile from "../../media/profile.jpg";
import NavBar from "./NavBar";
import { useContext } from "react";
import { LangContext } from "../../store/lang-context";

const MainHeader = () => {
  const isEng = useContext(LangContext).lang;
  return (
    <header>
      <div className={`${styles["header-top"]} `}>
        <img src={profile} alt="" className={styles["header__image"]} />

        <div className={styles["header__box"]}>
          <div className={styles["header__title"]}>
            {isEng ? "Ramin Shakooei" : "رامین شکویی"}
          </div>
          <div className={styles["header__subtitle"]}>
            {isEng ? "Front-end developer" : "توسعه‌دهنده فرانت‌اند"}
          </div>
        </div>
      </div>
      <NavBar />
    </header>
  );
};

export default MainHeader;
