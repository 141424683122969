import classes from "./Projects.module.css";
import pomodoroPic from "../../media/pomodoro.JPG";
import memorPic from "../../media/memor.jpg";
import reactTodoPic from "../../media/react-todo.JPG";
import todoPic from "../../media/todo.JPG";
import pigPic from "../../media/pig.JPG";
import wordlePic from "../../media/wordle.JPG";
import { useContext } from "react";
import { LangContext } from "../../store/lang-context";

const Projects = () => {
  const isEng = useContext(LangContext).lang;
  const projectArr = [
    {
      title: "Pomodoro",
      image: pomodoroPic,
      description: `
      <p>
      A pomodoro app based on 
      <a href='https://pomofocus.io' target='_blank'>pomofocus.io</a> with react.
      User can
        <ul><li>change pomodoro and breaks time</li>
        <li>set short breaks interval</li>
        <li>set timer start automatically</li>
        <li>view reports in weekly and monthly tables</li>
        <li>edit reports</li></ul>
      </p>
      <p>
      You can find demo <a href="https://itsramin.github.io/pomodoro/">here</a>.
      </p>
      
      
      `,
      faDescription: `
      <p>
      اپ پومودورو براساس سایت
      <a href='https://pomofocus.io' target='_blank'>pomofocus.io</a> که با react توسعه داده شده است.
      کاربر می‌تواند
        <ul><li>زمان پومودورو و استراحت‌ها را تغییر دهد</li>
        <li>تعداد تکرار استراحت کوتاه‌ها را تعیین کند</li>
        <li>شروع خودکار تایمر را تنظیم کند</li>
        <li>گزارش عملکرد خود را بصورت جدول هفتگی و ماهانه مشاهده نماید</li>
        <li>گزارشات را تغییر دهد</li></ul>
      </p>
      <p>
      دموی این سایت را می‌توانید در <a href="https://itsramin.github.io/pomodoro/"  >اینجا</a> مشاهده نمایید.
      </p>
      `,
      link: "https://itsramin.github.io/pomodoro/",
      languages: [{ name: "React", class: "react" }],
    },
    {
      title: "Memor",
      image: memorPic,
      description: `
      <p>
      A flashcard application for android developed with react native based on Leitner method. User can create his/her own flashcards or use online flashcards. Import and export cards (CSV) has been added to manipulate cards easily. 
      </p>
      <p>
      You can check the code in 
      <a href='https://github.com/itsramin/Memor/tree/v1.0.9' target='_blank'>Github</a>
      or download the apk from 
      <a href='https://github.com/itsramin/Memor/releases/download/v1.0.9/memor.1.0.9.4.apk' download>here</a>.
      </p>
      
      `,
      faDescription: `
      <p>
      اپلیکیشن فلش کارت مبتنی بر سیستم لایتنر که با ری‌اکت نیتیو توسعه داده شده است. کاربر می‌تواند کارت بسازد یا از کارت‌های آنلاین استفاده کند. import و  export کارت‌ها بصورت فایل CSV، از دیگر ویژگی‌های این برنامه است.
      </p>
      <p>
      برای مشاهده سورس کد می‌توانید به 
      <a href='https://github.com/itsramin/Memor/tree/v1.0.9' target='_blank'>گیتهاب</a>
      رجوع کنید و یا از 
      <a href='https://github.com/itsramin/Memor/releases/download/v1.0.9/memor.1.0.9.4.apk' download>اینجا</a>
      فایل نصبی اندروید را دریافت کنید.
      </p>
      `,
      link: "https://github.com/itsramin/Memor/tree/v1.0.9",
      languages: [{ name: "React Native", class: "rn" }],
    },
    {
      title: "ToDo app with React",
      image: reactTodoPic,
      link: "https://itsramin.github.io/todo-react/",
      description: `
      <p>
      A ToDo app like Microsoft ToDo with React. Some features are:
      </p>
      <ul><li>due date</li>
      <li>categories</li>
      <li>show missed tasks</li>
      <li>repetition interval</li>
      <li>search between all tasks</li>
      <li>sort tasks by date, alphabet and creation date</li></ul>
      
      <p>
      You can find demo <a href="https://itsramin.github.io/todo-react/">here</a>.
      </p>`,
      faDescription: `
      <p>
      اپ ToDo مشابه اپ Microsoft ToDo که با ری‌اکت نوشته شده است. برخی از قابلیت‌ها: 
      </p>
      <ul><li>تاریخ انجام کار</li>
      <li>پوشه‌بندی</li>
      <li> نمایش تسک‌های رد شده</li>
      <li>تکرار تسک</li>
      <li>جستجوی تسک‌ها</li>
      <li>مرتب سازی تسک‌ها برحسب تاریخ انجام، الفبا و تاریخ ساخت تسک</li></ul>
      <p>
      دموی این سایت را می‌توانید در <a href="https://itsramin.github.io/todo-react/"  >اینجا</a> مشاهده نمایید.
      </p>
      `,
      languages: [{ name: "React", class: "react" }],
    },
    {
      title: "ToDo list pwa",
      image: todoPic,
      description: `
        <p>
        A simple ToDo application without any library, working with HTML, CSS and JavaScript. The design pattern has been used is MVC. Some features are:
        </p>
        <ul><li>due date</li>
        <li>dark mode</li>
        <li>categories</li>
        <li>sort by time</li>
        <li>show missed tasks</li>
        <li>repetition interval</li>
        <li>responsive interface</li>
        <li>search between all tasks</li>
        <li>add task to Google Calendar</li></ul>
        
        <p>
        You can find demo <a href="https://todo-ramin.netlify.app/">here</a>.
        </p>
        `,
      faDescription: `
      <p>
      اپ ساده ToDo که فقط با CSS HTML و JavaScript نوشته شده است. از MVC بعنوان design pattern استفاده شده است. برخی از ویژگی‌ها:
      </p>

      <ul><li>تاریخ انجام کار</li>
      <li>تم تیره</li>
      <li>پوشه‌بندی</li>
      <li>مرتب سازی برحسب زمان</li>
      <li>نمایش تسک‌های رد شده</li>
      <li>تکرار تسک</li>
      <li>طراحی واکنشگرا</li>
      <li>جستجوی تسک‌ها</li>
      <li>اضافه کردن تسک به تقویم گوگل</li></ul>

      <p>
      دموی این سایت را می‌توانید در <a href="https://todo-ramin.netlify.app/"  >اینجا</a> مشاهده نمایید.
      </p>
      `,
      link: "https://todo-ramin.netlify.app/",
      languages: [
        { name: "JavaScrip", class: "js" },
        { name: "HTML", class: "html" },
        { name: "CSS", class: "css" },
        { name: "PWA", class: "pwa" },
      ],
    },
    {
      title: "Pig Game",
      image: pigPic,
      description: `
        <p>
        A developed version of <a href="https://github.com/jonasschmedtmann" rel="noreferrer" target="_blank">Jonas Schmedtmann</a>'s pig game of his javascript course. The main feature has been added is Single Player. The BOT's strategy is roll dice until current score reaches 15, then holds it.
        </p>
        
        <p>
        You can find demo <a href="https://pig-game-ramin.netlify.app/">here</a>.
        </p>`,

      faDescription: `
        <p>
        نسخه توسعه داده شده بازی Pig Game که در دوره آموزشی جاوااسکریپت <a href="https://github.com/jonasschmedtmann" rel="noreferrer" target="_blank">Jonas Schmedtmann</a> آمده است. قابلیت بازی با ربات به این نسخه اضافه شده است. استراتژی ربات این است که تا زمانی که مجموع تاس‌ها به 15 نرسیده است، دوباره تاس می‌اندازد.
        </p>

        <p>
        دموی این سایت را می‌توانید در <a href="https://pig-game-ramin.netlify.app/"  >اینجا</a> مشاهده نمایید.
        </p>`,

      link: "https://pig-game-ramin.netlify.app/",
      languages: [
        { name: "JavaScrip", class: "js" },
        { name: "HTML", class: "html" },
        { name: "CSS", class: "css" },
      ],
    },
    {
      title: "Persian Wordle",
      image: wordlePic,
      description: `
        <p>
        Persian wordle game with multiple categories such as cities, animals and fruits. The big different from main "Wordle" is that there is not a 5-character limitation.
        </p>
        
        <p>
        You can find demo <a href="https://wordle-ramin.netlify.app/">here</a>.
        </p>`,
      faDescription: `
        <p>
        Wordle فارسی با اضافه شدن موضوعاتی مثل شهرها، حیوانات و میوه‌ها. تفاوت اصلی این بازی با بازی اصلی، عدم محدودیت کلمات 5 حرفی است.
        </p>
        
        <p> دموی این سایت را می‌توانید در <a href="https://wordle-ramin.netlify.app/"  >اینجا</a> مشاهده نمایید.
        </p>
      `,
      link: "https://wordle-ramin.netlify.app/",
      languages: [
        { name: "JavaScrip", class: "js" },
        { name: "HTML", class: "html" },
        { name: "CSS", class: "css" },
      ],
    },
  ];
  return (
    <div>
      {projectArr.map((p) => {
        return (
          <article className={classes["article__item"]} key={p.title}>
            <div className={classes["article__image"]}>
              <img src={p.image} alt={p.title} />
            </div>
            <div className={classes["article__body"]}>
              <a
                className={classes["article__title"]}
                href={p.link}
                rel="noreferrer"
                target="_blank"
              >
                {p.title}
              </a>
              <div className={classes["article__info"]}>
                <div
                  className={classes["article__description"]}
                  dangerouslySetInnerHTML={{
                    __html: isEng ? p.description : p.faDescription,
                  }}
                ></div>
                <div className={classes["languages"]}>
                  {p.languages.map((l) => {
                    return (
                      <div
                        className={`${classes["language"]} ${classes[l.class]}`}
                        key={l.name}
                      >
                        {l.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </article>
        );
      })}
    </div>
  );
};

export default Projects;
