import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import MainHeader from "./components/header/Header";
import About from "./components/routes/About";
import Contacts from "./components/routes/Contacts";
import Skills from "./components/routes/Skills";
import Projects from "./components/routes/Projects";
import { LangContext } from "./store/lang-context";
import LanguageBtn from "./components/UI/LanguageBtn";
import { useContext } from "react";

function App() {
  const isEng = useContext(LangContext).lang;
  return (
    <div className={`${"App"} ${isEng ? "" : "rtl"}`}>
      <LanguageBtn />
      <MainHeader />
      <main className={`${"main"} ${isEng ? "" : "fa-text"}`}>
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
