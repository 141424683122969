import React, { useEffect, useState } from "react";

export const LangContext = React.createContext({
  lang: true,
  changeLang: () => {},
});

const LangProvider = ({ children }) => {
  const [lang, setLang] = useState(() => {
    const value = JSON.parse(localStorage.getItem("language"));

    return value ? value : false;
  });

  useEffect(() => {
    localStorage.setItem("language", JSON.stringify(lang));
  }, [lang]);

  const changeLangHandler = () => {
    setLang((prev) => !prev);
  };
  const langValue = {
    lang: lang,
    changeLang: changeLangHandler,
  };
  return (
    <LangContext.Provider value={langValue}>{children}</LangContext.Provider>
  );
};

export default LangProvider;
