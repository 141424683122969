import classes from "./Contacts.module.css";

import instagram from "../../media/icons/insta.svg";
import gmail from "../../media/icons/gmail.svg";
import github from "../../media/icons/github.svg";
import linkedin from "../../media/icons/linkedin.svg";
import phone from "../../media/icons/phone.svg";
import { useContext } from "react";
import { LangContext } from "../../store/lang-context";

const Contacts = () => {
  const isEng = useContext(LangContext).lang;
  const contactsArr = [
    {
      name: "number",
      link: "#",
      replace: true,
      icon: phone,
      text: "+98 939 867 29 55",
    },
    {
      name: "gmail",
      link: "mailto:raminshakooei@gmail.com",
      icon: gmail,
      text: "RaminShakooei@gmail.com",
    },
    {
      name: "insta",
      link: "https://www.instagram.com/ramin__shb/",
      icon: instagram,
      text: "/ramin__shb",
    },
    {
      name: "github",
      link: "https://github.com/itsramin",
      icon: github,
      text: "/itsramin",
    },

    {
      name: "linkedin",
      link: "https://www.linkedin.com/in/raminshakooei/",
      icon: linkedin,
      text: "/raminshakooei",
    },
  ];
  return (
    <div>
      <h3 className={classes["section__title"]}>
        {isEng
          ? "Social media and contacts"
          : "راه‌های ارتباطی و شبکه‌های اجتماعی"}
      </h3>
      <div className={classes["links"]}>
        {contactsArr.map((c) => {
          return (
            <div className={classes["link"]} key={c.name}>
              <a
                href={c.link}
                rel="noreferrer"
                target={c.replace ? "" : "_blank"}
                className={classes["contact--link"]}
              >
                <img
                  src={c.icon}
                  alt="instagram"
                  className={classes["icons"]}
                />
              </a>
              <span className={classes["link-data"]}>{c.text}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Contacts;
