import classes from "./Skills.module.css";

import ps from "../../media/icons/ps.png";
import ill from "../../media/icons/ill.png";
import react from "../../media/icons/react.png";
import git from "../../media/icons/git.png";
import rn from "../../media/icons/rn.png";
import sqlite from "../../media/icons/sqlite.png";
import html from "../../media/icons/html.png";
import css from "../../media/icons/css.png";
import js from "../../media/icons/js.png";
import xd from "../../media/icons/xd.png";
import trello from "../../media/icons/trello.png";
import { useContext } from "react";
import { LangContext } from "../../store/lang-context";

const Skills = () => {
  const isEng = useContext(LangContext).lang;
  const graphicArr = [ps, ill, xd, trello];
  const codeArr = [html, css, js, git, react, sqlite, rn];

  const skillArr = [
    {
      name: "graphic",
      text: `
      <p>
      Working as a graphic designer for social media, made some oportunities for me to upgrade my skills:
      </p>
      
      <ul><li>Working with Adobe Photoshop and Adobe Illustrator</li>
      <li>Time management of the projects with Trello</li>
      <li>Problem solving</li>
      <li>Responsibility and teamwork</li></ul>

      <p>
      Besides, I tried Adobe XD as I was interested in new softwares and technologies.
      </p>
    `,
      faText: `
        <p>
        کارکردن به عنوان طراح محتوای گرافیکی برای شبکه‌های اجتماعی، فرصت‌های مناسبی جهت کسب مهارت‌های مختلف را برای من ایجاد کرد.
        </p>
        
        <ul><li>کسب مهارت فنی با نرم‌افزارهای Adobe Photoshop و Adobe Illustrator</li>
        <li>مدیریت زمانی و فنی پروژه‌ها با نرم‌افزار Trello</li>
        <li>مهارت حل مسئله</li>
        <li>مسئولیت‌پذیری و روحیه کار تیمی</li></ul>

        <p>
        در کنار انجام کارهای گرافیکی بخاطر علاقه‌ام به تجربه تکنولوژی و نرم‌افزارهای جدید، مدتی هم روی نرم‌افزار Adobe XD وقت گذاشتم.
        </p>
      `,
      icons: graphicArr,
    },
    {
      name: "code",
      text: `
        <p>
        To start my career as a front-end developer, I passed CSS, HTML, JavaScript, Git and React courses and finally, I found my passion in mobile apps, so I learned React Native. SQLite was my next course to learn to manipulate databases in react native.
        </p>
      `,
      faText: `
        <p>
        برای شروع فرانت‌اند، دوره‌های React - Git - JavaScript - HTML - CSS را گذراندم و در ادامه متوجه علاقه‌ام به تولید اپ موبایل شدم و   React Native را یاد گرفتم. برای کار با دیتاست‌ها، SQLite را یاد گرفتم.
        </p>
      `,
      icons: codeArr,
    },
  ];
  return (
    <>
      {skillArr.map((skill) => {
        return (
          <div className={classes["skill-box"]} key={skill.name}>
            <div
              className={classes["skill-text"]}
              dangerouslySetInnerHTML={{
                __html: isEng ? skill.text : skill.faText,
              }}
            ></div>

            <div className={classes["app-icons"]}>
              {skill.icons.map((icon) => {
                return (
                  <img
                    className={classes["app-icons__item"]}
                    src={icon}
                    key={icon}
                    alt="icon"
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Skills;
